import { define, html } from "hybrids";

export default define({
  tag: "v-home-nav",
  render: () => html`
    <v-ui-flexbox flow="row wrap" layout="center">
      <slot></slot>
    </v-ui-flexbox>
  `.css`
    :host {
      display: block;
      pointer-events: none;
      padding: 15px;
    }
  
    v-ui-flexbox {
      padding: 20;
      --v-ui-font-size-button: 15px;
    }

    v-ui-flexbox ::slotted(*) {
      height: 32px;
      pointer-events: auto;
    }

    v-ui-flexbox ::slotted([color="text-primary"]) {
      text-decoration: underline;
    }

    @media (min-width: 992px) {
      :host {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: var(--v-ui-z-index-dialog);
        padding: 0;
      }

      v-ui-flexbox {
        margin: 0;
        height: 70px;
      }

      v-ui-flexbox ::slotted(*) {
        margin: 0 24px;
      }
    }
  `,
});

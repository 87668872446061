import { define, html, router } from "hybrids";
import layout from "~/factories/layout";
import icons from "../assets/icons";

import VideoList, { List } from "~/modules/website/views/VideoList";

interface VForOrganizersArtistsView {}

export default define<VForOrganizersArtistsView>({
  [router.connect]: { stack: [VideoList], url: "/organize/organizers" },
  tag: "v-home-for-organizers-view",
  render: layout("block"),
  content: () => html`
    <v-home-section>
      <v-ui-text variant="h1" weight="black" xgutter="1">
        Sell tickets & stream video
      </v-ui-text>
      <v-ui-text color="text-secondary" ygutter="1" xgutter="1">
        33% of our low fees go to
        <v-ui-link underline href="/pages/ucif">
          the Universal Creator Income Fund
        </v-ui-link>
      </v-ui-text>
      <v-ui-flexbox layout="center" slot="cta">
        <v-ui-button
          href="/e/new"
          name="Create an event"
          color="primary"
          type="cta"
          uppercase
        ></v-ui-button>
      </v-ui-flexbox>
    </v-home-section>

    <v-home-section>
      <v-ui-text variant="h2" weight="bold" xgutter="1">Ticketing</v-ui-text>
      <v-home-organize-card>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.fees}" alt="Lowest fees" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Lowest fees</v-ui-text>
          <v-ui-text ygutter="1">
            Our ticketing fees are some of the lowest out there. 33% of the fees
            go into a Universal Creator Income Fund to support artist communities.
            <v-ui-link underline href="/pages/fees">
              Learn more.
            </v-ui-link>
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.bank}" alt="Money in the bank" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Money in the bank</v-ui-text>
          <v-ui-text ygutter="1">
            With Stripe Connect and Paypal Marketplace, tickets and donations
            from your events go straight to your bank with no added wait time.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.payments}" alt="Accept payments globally" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Accept payments globally</v-ui-text>
          <v-ui-text ygutter="1">
            We support 135 currencies. If a currency isn't supported, we go to
            great lengths to help find a way to support you.
          </v-ui-text>
        </section>
      </v-home-organize-card>
    </v-home-section>

    <v-home-section>
      <v-ui-text variant="h2" weight="bold">Streaming</v-ui-text>
      <v-home-organize-card>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.upload}" alt="Upload and schedule" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Upload and schedule</v-ui-text>
          <v-ui-text ygutter="1">
            Stream pre-recorded video in HD. Our team is here to help.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.streaming}" alt="RTMP Streaming" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">RTMP streaming</v-ui-text>
          <v-ui-text ygutter="1">
            Use OBS or any other encoder to stream to your dedicated Viewcy
            stream key. Set the video to be available on-demand for a limited
            time, or open ended.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.venue}" alt="Interactive virtual venue" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Interactive virtual venue</v-ui-text>
          <v-ui-text ygutter="1">
            Audiences can enjoy the show, chat, applaud, donate, and follow
            artists and friends. Try it out by exploring our
            <v-ui-link underline href="${router.url(VideoList, { listName: List.ONDEMAND })}">
              free concerts on demand.
            </v-ui-link>
          </v-ui-text>
        </section>
      </v-home-organize-card>
    </v-home-section>

    <v-home-section>
      <v-ui-text variant="h2" weight="bold">Promotion</v-ui-text>
      <v-home-organize-card>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.email}" alt="Engage artists" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Engage artists</v-ui-text>
          <v-ui-text ygutter="1">
            Our collaborative, community driven architecture encourages artists
            to promote, and allows them to manage their own profile, saving time
            for venues and increasing ticket sales.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img
              src="${icons.megaphone}"
              alt="Unparalleled promotional support"
            />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">
            Unparalleled promotional support
          </v-ui-text>
          <v-ui-text ygutter="1">
            We create beautiful, sharable content and activate your artists to
            promote your events.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.crown}" alt="Sponsorships" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Sponsorships</v-ui-text>
          <v-ui-text ygutter="1">
            Viewcy connects venues with sponsors that pay to promote to online
            viewers.
          </v-ui-text>
        </section>
      </v-home-organize-card>
    </v-home-section>
    
    <v-home-section>
      <v-ui-text variant="h2" weight="bold">Logistics</v-ui-text>
      <v-home-organize-card>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.revenue}" alt="No wait time on revenue" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">No wait time on revenue</v-ui-text>
          <v-ui-text ygutter="1">
            With Stripe connect and Paypal marketplace, the money from tickets
            and donations go straight to your bank account.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.email}" alt="Email data" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">Email data</v-ui-text>
          <v-ui-text ygutter="1">
            Collect emails for your events and streams. Export the data to CSV,
            and upload it directly to your email software. Hosting events on
            Viewcy is a powerful way to grow your email list from your live
            content.
          </v-ui-text>
        </section>
        <section>
          <v-ui-text ygutter="1">
            <img src="${icons.vip}" alt="VIP access" />
          </v-ui-text>
          <v-ui-text variant="h4" ygutter="1">VIP access</v-ui-text>
          <v-ui-text ygutter="1">
            Issue direct access links or create secret tickets to give easy
            access to your VIP's and special guests.
          </v-ui-text>
        </section>
      </v-home-organize-card>
    </v-home-section>
  `,
});

import { html, define, router, Component } from "hybrids";

interface VHomeSection {
  back: boolean;
  backUrl: string;
}

export default define<VHomeSection>({
  tag: "v-home-section",
  back: false,
  backUrl: "",
  render: ({ back, backUrl }) => html`
    ${back &&
    html`<v-ui-flexbox flow="row" id="button">
      <v-ui-button
        href="${backUrl || router.backUrl()}"
        icon="back"
        variant="outline"
        type="transparent"
        color="text-primary"
      ></v-ui-button>
    </v-ui-flexbox>`}
    <slot></slot>
    <slot name="cta"></slot>
  `.css`
    :host {
      display: block;
      padding: calc(var(--v-ui-layout-spacing) * 3 / 4) 0;
    }

    :host([back]) {
      padding-top: 0;
    }

    #button {
      padding: var(--v-ui-layout-spacing);
    }

    #button v-ui-button {
      width: 40px;
    }

    :host ::slotted(v-ui-text) {
      text-align: center;
      line-height: 1.2;
    }

    slot[name="cta"]::slotted(*) {
      margin-top: 40px;
    }

    @media (min-width: 1024px) {
      #button {
        margin-bottom: -86px;
      }
    }
  `,
});

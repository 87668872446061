import { html, define, router, Component } from "hybrids";
import layout from "~/factories/layout";

interface VHomePagination {
  page: number;
  count: number;
  limit: number;
}

export default define<VHomePagination>({
  tag: "v-home-pagination",
  page: 1,
  count: 0,
  limit: 0,
  render: layout("flex"),
  content: ({ page, count, limit }) => html`
    <v-ui-flexbox layout="center" flow="row" gap="small">
      ${page > 1 &&
      html`<v-ui-button
        href="${router.currentUrl({ page: page - 1, scrollToTop: true })}"
        color="white"
        name="< Previous page"
      ></v-ui-button>`}
      ${count === limit &&
      html`<v-ui-button
        href="${router.currentUrl({ page: page + 1, scrollToTop: true })}"
        color="secondary"
        name="Next page >"
      ></v-ui-button>`}
    </v-ui-flexbox>
  `,
});

import { define, html } from "hybrids";

import eventbriteImage from "../assets/fees-eventbrite.svg";
import brownpaperticketsImage from "../assets/fees-brownpapertickets.svg";
import viewcyImage from "../assets/fees-viewcy.svg";

interface VHomeTickets {
  eventbrite: string;
  brownpapertickets: string;
  viewcy: string;
}

export default define<VHomeTickets>({
  tag: "v-home-tickets",
  eventbrite: "",
  brownpapertickets: "",
  viewcy: "",
  render: ({ eventbrite, brownpapertickets, viewcy }) => html`
    <v-ui-flexbox gap="small" layout="start center">
      <img src="${eventbriteImage}" />
      <v-ui-text
        variant="caption"
        color="text-secondary"
        weight="semibold"
        uppercase
      >
        Eventbrite
      </v-ui-text>
      <v-ui-text variant="h3" weight="bold">
        <v-home-badge color="tickets-badge-competition">
          ${eventbrite}
        </v-home-badge>
      </v-ui-text>
    </v-ui-flexbox>
    <v-ui-flexbox gap="small" layout="start center">
      <img src="${brownpaperticketsImage}" />
      <v-ui-text
        variant="caption"
        color="text-secondary"
        weight="semibold"
        uppercase
      >
        Brown Paper Tickets
      </v-ui-text>
      <v-ui-text variant="h3" weight="bold">
        <v-home-badge color="tickets-badge-competition">
          ${brownpapertickets}
        </v-home-badge>
      </v-ui-text>
    </v-ui-flexbox>
    <v-ui-flexbox gap="small" layout="start center">
      <img src="${viewcyImage}" />
      <v-ui-text
        variant="caption"
        color="text-secondary"
        weight="semibold"
        uppercase
      >
        Viewcy
      </v-ui-text>
      <v-ui-text variant="h3" weight="bold">
        <v-home-badge color="tickets-badge-viewcy">${viewcy}</v-home-badge>
      </v-ui-text>
    </v-ui-flexbox>
  `.css`
    :host {
      margin: var(--v-ui-layout-spacing) 0;
      display: grid;
      grid-gap: calc(2 * var(--v-ui-layout-spacing)) 0;
      --v-ui-color-tickets-badge-competition: #FAE789;
      --v-ui-color-tickets-badge-viewcy: #99F7BF;
    }

    img {
      margin-right: -48px;
    }

    @media (min-width: 768px) {
      :host {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  `,
});

import { define, html } from "hybrids";

import background from "../assets/background.svg";

export default define({
  tag: "v-home-layout",
  render: () =>
    html`
      <slot name="nav"></slot>
      <div id="container">
        <slot></slot>
      </div>
    `.css`
      :host {
        display: block;
        background: url("${background}") center top repeat-y;
        background-size: 400% auto;
        min-height: 100vh;
        --v-ui-font-size-h2: 30px;
      }
      
      #container {
        width: 100%;
        max-width: 1200px;
        margin: 0px auto;
        padding: 40px 0;
      }
      
      @media (min-width: 768px) {
        :host {
          background-size: 200% auto;
          --v-ui-layout-spacing: 40px;
        }
      } 
    `,
});

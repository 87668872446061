import { define, html, store, router } from "hybrids";
import layout from "~/factories/layout";

import Memberships from "./Memberships"

export default define({
  [router.connect]: { url: "/pages/about_us" },
  tag: "v-home-about-us-view",
  render: layout("block"),
  content: () => html`
    <v-home-page>
      <v-home-section>
        <v-ui-text variant="h1" weight="black" xgutter="1">
          About Us
        </v-ui-text>
      </v-home-section>
      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          Overview
        </v-ui-text>
        Viewcy’s mission is to preserve music cultures from around the world.
        We focus on supporting musicians, fostering intercultural dialogue,
        and increasing cross-border collaboration. And we work with and support
        emerging talent and musical leaders from all over the planet.
      </v-ui-text>
      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          How Viewcy works
        </v-ui-text>
        On Viewcy, event producers sell tickets and stream their shows live,
        while  music lovers access our community-funded, artists-curated catalog
        of music.<v-ui-text weight="bold" inline> 33% of fees collected from memberships and ticket fees are
        circulated back to support artists to create new music.</v-ui-text>
      </v-ui-text>
      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          Get involved
        </v-ui-text>
        Everyone can participate in preserving our cultural heritage.
        For event creators it costs nothing. For members, it costs as little
        as a cappuccino.
      </v-ui-text>
      <v-ui-text ygutter="1">
        <ol>
          <li>
            <v-ui-link
              href="${router.url(Memberships, { scrollToTop: true })}"
              underline>
              Become a member.
            </v-ui-link>
            Starting at $5/month
          </li>
          <li>
            <v-ui-link
              href="/organize/organizers"
              underline>
              Produce events and sell tickets using Viewcy
            </v-ui-link>
          </li>
        </ol>
      </v-ui-text>
      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          Paying it forward: A story
        </v-ui-text>
        In my early attempts at being a professional musician,
        a friend and I went into the NYC subway to play for an hour
        and see if we could make some money. At times, we were working
        for $25 a night. We thought, <i>could this be better?</i>
      </v-ui-text>
      <v-ui-text ygutter="1">
        The experience was demoralizing. The sound from the subway cars was
        deafening, and the people avoiding eye contact was disheartening.  The
        only energy we got was from a homeless man sitting on the floor across
        the tracks, sending us a thumbs up through his half-cut gloves. He was
        listening, and that was enough.
      </v-ui-text>
      <v-ui-text ygutter="1">
        As we prepared to leave, our homeless friend made the journey onto
        our platform, walked up, and thanked us for the music. He then handed
        over whatever change he had. It was the most profound act of generosity
        I have ever experienced.
      </v-ui-text>
      <v-ui-text ygutter="1">
        In a humble attempt to pass it forward, Viewcy is dedicated to him.
      </v-ui-text>
      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          Curation on Viewcy
        </v-ui-text>
        On Viewcy, our music catalog is curated by artists. In 2020, at the
        peak of the pandemic, I invited several dear friends from India, where
        I spent over two decades learning, to kick us off.  Underrepresented in
        their country, and performers of the highest caliber, they seemed like
        the perfect starting point.
      </v-ui-text>
      <v-ui-text ygutter="1">
        After their performance, we invited them to select the next group
        of artists. The next group of artists would then go on to invite the
        next group, and so on.  From week to week, our network expanded and now
        includes musicians from West Africa, North Africa, South Asia,
        Europe, and the Americas.
      </v-ui-text>
    </v-home-page>
  `.css`
    v-home-page {
      box-sizing: border-box;
    }
    v-ui-text {
      margin-top: .5rem;
    }
    ol {
      padding-left: 1em;
    }
  `,
});

import {define, html, router} from "hybrids";

interface VForOrganizersArtistsView {
}

export default define<VForOrganizersArtistsView>({
  [router.connect]: {url: "/pages/memberships"},
  tag: "v-home-memberships-view",
  render: () => html`
    <v-home-page>
      <v-home-section>
        <v-ui-text variant="h1" weight="black" ygutter="1">Join the movement. Become a member.</v-ui-text>
        <v-ui-flexbox flow="row" layout="center" gap="small" slot="cta">
          <v-ui-button
            href="/memberships/select"
            name="Become a member" color="primary" type="cta" uppercase
          ></v-ui-button>
        </v-ui-flexbox>
      </v-home-section>
      <v-home-stats-card></v-home-stats-card>
      <v-home-membership-card></v-home-membership-card>
      <v-home-section>
        <v-ui-text variant="h2" weight="bold" ygutter="1" center>FAQ</v-ui-text>
        <v-ui-drop-down-content title="What are my membership benefits?">
          <ul>
            <li>Be a part of preserving our global cultural heritage by funding artist-curated new music projects.
            </li>
            <li>Get access to knowledge: lectures, talks, and demonstrations.</li>
            <li>Get early access to new music being created.</li>
          </ul>
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="How is my membership spent?">
          Seventy percent is paid to artists to create new music. Thirty percent is used to develop the Viewcy
          platform.
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="Who decides on which artists get funded?">
          Artists who have performed on Viewcy can curate Sessions by submitting proposals. The Viewcy Team assesses
          proposals based on their cultural significance, the diversity of content they bring to the platform, the
          popularity of the artists, and the production costs.
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="How often is new content added to Viewcy?">
          Every week! The more members, the more music gets created.
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="How often is Knowledge content added?">
          New Knowledge content is created and released every month. The amount of Knowledge content we can create
          increases with every membership.
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="How do I cancel my membership?">
          Log into your Viewcy account on your browser and click on “Membership” from the main menu in the top right
          of
          the browser.
          <ul>
            <li>If you purchased on IOS, go to your IOS Settings / Subscriptions and cancel.</li>
          </ul>
        </v-ui-drop-down-content>
        <v-ui-drop-down-content title="Can I change my membership tier?">
          Yes. Log into your Viewcy account and click on “Membership” from the main menu in the top right of the
          browser.
        </v-ui-drop-down-content>
      </v-home-section>
      <v-home-section>
        <v-ui-flexbox flow="row" layout="center" gap="small">
          <v-ui-button
            href="/memberships/select"
            name="Become a member" color="primary" type="cta" uppercase
          ></v-ui-button>
        </v-ui-flexbox>
      </v-home-section>
    </v-home-page>
  `.css`
    :host {
      --v-ui-font-size-h1: 40px;
      --v-ui-font-size-h2: 38px;
    }
    v-ui-drop-down-content:last-child {
      border-bottom: 1px solid;
      border-bottom: 1px solid #E9E9E9;
    }
    v-ui-section:last-child {
      padding-top: 20px;
    }
  `,
});

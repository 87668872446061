import { define, html } from "hybrids";

import "../../broadcast/elements/Player";

interface VHomeVideoHeader {
  player: boolean;
}

const BG_VIDEO_URL =
        "https://stream.mux.com/foCJNuXRdvGEO8Y54b8d1kj2fcFXoY01rvAquIbouXW4.m3u8";
const MAIN_VIDEO_URL =
        "https://stream.mux.com/Gyfj8JTPhOkuS1KcDCYzFvmY02I3Dpd02MiENDc23eNyk.m3u8";

export default define<VHomeVideoHeader>({
  tag: "v-home-video-header",
  player: false,
  render: ({ player }) => html`
    <template
      layout="row center relative height::495px:675px overflow"
      layout[player]="height::auto:none"
    >
      ${player
        ? html`
          <v-broadcast-player
            layout="grow width:::1200px"
            src="${MAIN_VIDEO_URL}"
          ></v-broadcast-player>
        `
        : html`
          <v-broadcast-player
            id="background-video"
            layout="grow width::880px overflow"
            src="${BG_VIDEO_URL}"
            background
          ></v-broadcast-player>
          <div id="content-container" layout="column absolute inset margin:4">
            <div
              layout="column items:center gap:4"
              layout@768px="width:::540px"
              layout@1200px="width:::100%"
            >
              <v-ui-text layout="block:center" variant="h1" color="button">
                A community in support of musicians
              </v-ui-text>
              <v-ui-text layout="block:center" variant="h5" weight="normal" color="button">
                Explore&nbsp;music. Produce&nbsp;events. Sponsor&nbsp;culture.
              </v-ui-text>
              <v-ui-button
                variant="inline"
                onclick="${html.set("player", true)}"
              >
                <v-ui-icon
                  layout="block width:67px"
                  layout@1200px="width:110px"
                  name="play"
                  color="button"
                ></v-ui-icon
                >
              </v-ui-button>
            </div>
          </div>
          <v-website-app-badges flow="row"/>
        `}
    </template>
  `.css`
    :host {
      background: black;
      --v-ui-line-height: 1.2em;
      --v-ui-font-size-h1: 40px;
    }

    #background-video {
      opacity: 0.36;
    }
    
    v-website-app-badges {
      position: absolute;
      bottom: 25px;
    }
    
    #content-container {
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 1200px) {
      :host {
        --v-ui-font-size-h1: 64px;
        --v-ui-font-size-h4: 36px;
      }
      
      #content-container {
        justify-content: center;
      }
    }
  `,
});

import { define, html } from "hybrids";
import layout from "~/factories/layout";
import icons from "~/modules/home/assets/icons";

export default define({
  tag: "v-home-membership-card",
  render: layout("block"),
  content: () => html`
    <v-home-organize-card>
      <section>
        <v-ui-text ygutter="1">
          <img src="${icons.support}" alt="Lowest fees"/>
        </v-ui-text>
        <v-ui-text variant="h4" ygutter="1">Support the creation of new music</v-ui-text>
        <v-ui-text ygutter="1">
          70% of subscriptions fund new music, curated by the artists themselves.
        </v-ui-text>
      </section>
      <section>
        <v-ui-text ygutter="1">
          <img src="${icons.knowledge}" alt="Money in the bank"/>
        </v-ui-text>
        <v-ui-text variant="h4" ygutter="1">Exclusive access to Knowledge</v-ui-text>
        <v-ui-text ygutter="1">
          Lectures, demonstrations, and talks by master musicians.
        </v-ui-text>
      </section>
      <section>
        <v-ui-text ygutter="1">
          <img src="${icons.premium}" alt="Accept payments globally"/>
        </v-ui-text>
        <v-ui-text variant="h4" ygutter="1">Exclusive access to Pre-releases</v-ui-text>
        <v-ui-text ygutter="1">
          Get early access to all new music being created on the platform.
        </v-ui-text>
      </section>
    </v-home-organize-card>
  `
})

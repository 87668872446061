import { Model, store } from "hybrids";
import { get } from "../utils/api";

interface Stats {
  projectsFundedCount: number;
  countriesCount: number;
  payoutsSum: number;
}

const Stats: Model<Stats> = {
  projectsFundedCount: 0,
  countriesCount: 0,
  payoutsSum: 0,
  [store.connect]: () => get("/stats_settings"),
};

export default Stats;

import { define, html } from "hybrids";

interface VHomePage {
}

export default define<VHomePage>({
  tag: "v-home-page",
  render: () => html`
    <slot></slot>
    <div id="footer">
      <slot name="footer"></slot>
    </div>
  `.css`
    :host {
      display: block;
      margin: 0 auto;
      padding: 0 var(--v-ui-layout-spacing);
      
      --v-ui-color-link: #18A0FB;
      --v-ui-color-page-highlight: #D60B52;
      ;
    }

    #footer ::slotted(*) {
      border: 1px solid var(--v-ui-color-background);
      border-radius: 14px;
      padding: var(--v-ui-layout-spacing);
    }
  `,
});

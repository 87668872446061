import { html, define, Component } from "hybrids";

export default define({
  tag: "v-home-organize-card",
  render: () => html`
    <v-home-card>
      <slot></slot>
    </v-home-card>
  `.css`
    :host { display: block }
  
    v-home-card {
      margin: 40px var(--v-ui-layout-spacing);
      display: grid;
      grid-auto-flow: row;
      grid-gap: calc(var(--v-ui-layout-spacing) * 2);
      padding: calc(var(--v-ui-layout-spacing) * 3/2) var(--v-ui-layout-spacing);
      text-align: center;
    }

    @media (min-width: 768px) {
      v-home-card {
        grid: auto / repeat(3, 1fr);
        grid-gap: var(--v-ui-layout-spacing);
      }
    }

    @media (min-width: 1024px) {
      v-home-card {
        text-align: left;
      }
    }
  `,
});

import { define, html, router } from "hybrids";
import layout from "~/factories/layout";

import Discover from "./Discover";
import ForArtists from "./ForArtists";
import ForOrganizers from "./ForOrganizers";
import Memberships from './Memberships';
import AboutUs from './AboutUs';
import Ucif from './Ucif';

interface VHomeRoot {
  views: HTMLElement[];
}

export default define<VHomeRoot>({
  tag: "v-home-root",
  views: router([ForArtists, ForOrganizers, Memberships, Discover, AboutUs, Ucif]),
  render: layout("block"),
  content: ({ views }) => html`
    <v-home-layout>
      <v-home-nav slot="nav">
        <v-ui-button
          href="${router.url(Discover, { scrollToTop: true })}"
          type="transparent"
          name="Explore"
          color="text-${router.active(Discover) ? "primary" : "secondary"}"
        ></v-ui-button>
        <v-ui-button
          href="${router.url(ForOrganizers, { scrollToTop: true })}"
          type="transparent"
          name="Create an event"
          color="text-${router.active(ForOrganizers) ? "primary" : "secondary"}"
        ></v-ui-button>
        <v-ui-button
          href="${router.url(Memberships, { scrollToTop: true })}"
          type="transparent"
          name="Become a member"
          color="text-${router.active(Memberships) ? "primary" : "secondary"}"
        ></v-ui-button>
      </v-home-nav>
      ${router.active(Discover) &&
      html`
        <v-home-video-header slot="nav"></v-home-video-header>`}
      ${views}
    </v-home-layout>
  `,
});

import { html, define, Component, store } from "hybrids";
import { isPast } from "date-fns";
import { enUS } from "date-fns/locale";
import { format, utcToZonedTime } from "date-fns-tz";

import Course from "~/stores/Course";
import ArtistVideo from "~/stores/ArtistVideo";

interface VHomeCourseCard {
  course: Course | ArtistVideo;
  start: Date | null;
  end: Date | null;
  session: boolean;
}

export default define<VHomeCourseCard>({
  tag: "v-home-course-card",
  course: undefined,
  session: {
    set: ({ course }, lastValue) => lastValue ?? (course as Course).session,
  },
  start: ({ course }) => {
    if (!("startsAt" in course)) return null;
    if (!course) return null;

    const startDate = new Date((course as Course).startsAt);

    return utcToZonedTime(startDate, (course as Course).timezone);
  },
  end: ({ start, course }) => {
    if (!course) return null;

    const endDate = new Date((course as Course).endsAt);
    return start && utcToZonedTime(endDate, (course as Course).timezone);
  },
  render: ({ course, start, end, session }) => html`
    <v-ui-link href="${course.url}" block>
      <v-home-card class="${{ session }}">
        <v-ui-poster src="${course.thumbnail}">
          ${start &&
          html`<div id="day">
            <v-ui-text weight="bold">${format(start, "dd")}</v-ui-text>
            <v-ui-text weight="semibold">${format(start, "MMM")}</v-ui-text>
          </div>`}
        </v-ui-poster>
        ${!session &&
        html`<v-ui-avatar
          id="avatar"
          src="${course.owner?.avatarThumbnail}"
        ></v-ui-avatar>`}
        <v-ui-flexbox id="content">
          <section>
            <v-ui-text id="name" weight="semibold" ygutter="1">
              ${course.name}
            </v-ui-text>
            <v-ui-text variant="caption" ygutter="1">
              <v-ui-text inline variant="caption" color="text-secondary">
                By
              </v-ui-text>
              ${course.owner?.name}
            </v-ui-text>
          </section>
          ${start &&
          end &&
          html`<section>
            <v-ui-flexbox flow="row" layout="start center">
              <v-ui-icon
                id="time-icon"
                name="time"
                size="small"
                color="text-secondary"
              ></v-ui-icon>
              <v-ui-text variant="caption" color="text-secondary">
                ${format(start, "MMMM do, p", {
                  timeZone: (course as Course).timezone,
                  locale: enUS,
                })}
                ${!(course as Course).session &&
                html`-
                ${format(start, "MMMM do") == format(end, "MMMM do")
                  ? html`${format(end, "p", {
                      timeZone: (course as Course).timezone,
                      locale: enUS,
                    })}`
                  : html`${format(end, "MMMM do, p", {
                      timeZone: (course as Course).timezone,
                      locale: enUS,
                    })}`} `}
                ${format(start, " (zzz)", {
                  timeZone: (course as Course).timezone,
                  locale: enUS,
                })}
                ${isPast(start) &&
                !isPast(end) &&
                ((course as Course).session
                  ? html`<v-website-badge color="primary">New</v-website-badge>`
                  : html`
                      <v-website-badge color="primary">
                        Happening now
                      </v-website-badge>
                    `)}
              </v-ui-text>
            </v-ui-flexbox>
          </section>`}
          ${!!course.sponsors.length &&
          html`<section>
            <v-ui-text variant="caption" color="text-secondary" ygutter="1">
              Sponsors
            </v-ui-text>
            <v-ui-flexbox flow="row wrap" layout="start">
              ${course.sponsors.map(
                (sponsor) =>
                  html`<img
                    class="sponsor"
                    src="${sponsor.image}"
                    title="${sponsor.name}"
                  />`
              )}
            </v-ui-flexbox>
          </section>`}
          ${session &&
          !!course.collaborators.length &&
          html`
            <section>
              <v-ui-text variant="caption" color="text-secondary" ygutter="1">
                Artists
              </v-ui-text>
              <v-ui-flexbox flow="row">
                ${course.collaborators.map(
                  (user) =>
                    html`<v-ui-avatar
                      src="${user.avatarThumbnail}"
                      title="${user.name}"
                    ></v-ui-avatar>`
                )}
              </v-ui-flexbox>
            </section>
          `}
        </v-ui-flexbox>
      </v-home-card>
    </v-ui-link>
  `.css`
    :host {
      display: block;
      --v-ui-avatar-size: 40px;
      --v-ui-text-gutter: 4px;
      --v-ui-color-happening-now-badge: #249cd2;
    }

    v-ui-link, v-home-card {
      height: 100%;
    }

    v-ui-poster {
      border-radius: 16px 16px 0 0;
    }

    v-ui-avatar {
      border: 2px solid white;
      background-color: var(--v-ui-color-background);
    }

    #avatar {
      margin-left: 16px;
      margin-top: -20px;
    }

    #content {
      padding: 8px 16px 16px;
      --v-ui-avatar-size: 32px;
    }

    #content section:not(:last-child) {
      margin-bottom: 8px;
    }

    #content v-ui-avatar {
      margin-right: -8px;
    }

    #content img.sponsor {
      min-width: 0;
      max-height: 30px;
      flex: 0 1 auto;
      margin: 8px 8px 8px 0;
    }

    #time-icon {
      margin-right: 4px;
    }

    #day {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      text-align: center;
      border-radius: 16px 0;
      background: var(--v-ui-color-date);
      padding: 8px 0;
    }

    #day v-ui-text {
      color: white;
      text-transform: uppercase;
      line-height: 1;
      font-size: 26px;
    }

    #day v-ui-text:last-child {
      font-size: 10px;
      letter-spacing: 0.245em;
    }

    @media (min-width: 1024px) {
      v-home-card.session {
        height: auto;
        display: grid;
        grid-auto-flow: column;
        grid: auto / 380px auto;
        grid-gap: 24px;
        padding: 30px;
      }

      v-home-card.session #name {
        font-size: 22px;
        font-weight: bold;
      }

      v-home-card.session v-ui-poster {
        border-radius: 12px;
      }

      v-home-card.session #day {
        border-radius: 12px 0;
      }

      v-home-card.session #content {
        padding: 0;
      }
    }
  `,
});

import { define, html, store } from "hybrids";
import Stats from "~/stores/Stats";
import mediaQuery from "~/factories/mediaQuery";
import { formatPrice } from "~/utils/currency";

interface VHomeStatsCard {
  stats: Stats;
  isDesktop: boolean;
}

export default define<VHomeStatsCard>({
  tag: "v-home-stats-card",
  stats: store(Stats),
  isDesktop: mediaQuery("(min-width: 768px)"),
  render: ({ stats, isDesktop }) => html`
    <v-home-card>
      <v-ui-flexbox class="container" flow="${isDesktop ? "row" : "column"}">
        <v-ui-flexbox class="stat separator" grow="1" layout="center center">
          <v-ui-text weight="bold" variant="h2">${store.ready(stats) ? stats.projectsFundedCount : ""}</v-ui-text>
          <v-ui-text color="text-secondary">Projects funded</v-ui-text>
        </v-ui-flexbox>
        <v-ui-flexbox class="stat separator" grow="1" layout="center center">
          <v-ui-text weight="bold" variant="h2">${store.ready(stats) ? formatPrice(stats.payoutsSum, 'USD') : ""}
          </v-ui-text>
          <v-ui-text color="text-secondary">Paid to artists</v-ui-text>
        </v-ui-flexbox>
        <v-ui-flexbox class="stat" grow="1" layout="center center">
          <v-ui-text weight="bold" variant="h2">${store.ready(stats) ? stats.countriesCount : ""}</v-ui-text>
          <v-ui-text color="text-secondary">Countries</v-ui-text>
        </v-ui-flexbox>
      </v-ui-flexbox>
    </v-home-card>
  `.css`
    :host {
      display: block;
      max-width: 652px;
      margin: auto;
      margin-top: 50px;
      --v-ui-font-size-h2: 32px;
    }
    .container {
      padding: 0px 25px;
    }
    .separator {
      border-bottom: 1px solid #E9E9E9;
      border-right: none;
    }
    .stat {
      padding: 25px 0px;
    }
    @media(min-width: 768px) {
      .container {
        padding: 30px 0px;
      }
      .separator {
        border-right: 1px solid #E9E9E9;
        border-bottom: none;
      }
      .stat {
        padding: 0;
      }
    }
  `
});

import { define, html } from "hybrids";

export default define({
  tag: "v-home-card",
  render: () => html`
    <slot></slot>`.css`
    :host {
      display: block;
      background: white;
      box-shadow: 4px 12px 40px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      overflow: hidden;
    }
  `,
});

import { define, html } from "hybrids";

interface VHomeBadge {
  size: "" | "large";
  color: "";
}

export default define<VHomeBadge>({
  tag: "v-home-badge",
  size: "",
  color: "",
  render: ({ color }) => html`<slot></slot>`.css`
    :host {
      display: block;
      background: var(--v-ui-color-${color}, white);
      padding: 0.25em 0.75em;
      border-radius: 0.5em;
    }

    :host([size="large"]) {
      padding: 0.75em 1.5em;
      border-radius: 0.75em;
      box-shadow: 4px 12px 40px rgba(0, 0, 0, 0.05);
    }
  `,
});

import { html, define, Component } from "hybrids";

interface VHomeGrid {
  session: boolean;
  pending: boolean;
}

export default define<VHomeGrid>({
  tag: "v-home-grid",
  session: false,
  pending: false,
  render: () => html`<slot></slot>`.css`
    :host {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      grid-gap: 30px;
      padding: var(--v-ui-layout-spacing);
      margin-top: var(--v-ui-layout-spacing);
      transition: opacity 200ms;
    }

    :host([session]) {
      grid-template-columns: 100%;
    }

    :host([pending]) {
      opacity: 0.5;
    }
  `,
});

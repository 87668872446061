import { define, html } from "hybrids";
import Course from "~/stores/Course";

interface VHomeCoursePreview {
  courses: Course[];
  href: string;
  session: boolean;
  more: boolean;
  limit: number;
}

export default define<VHomeCoursePreview>({
  tag: "v-home-course-preview",
  courses: undefined,
  href: "",
  session: false,
  limit: 3,
  more: ({ courses, limit }) => courses.length > limit,
  render: ({ more, session, limit }) => html`
    <div id="container" class="${{ more, session }}">
      <div id="grid">
        <slot></slot>
      </div>
    </div>

    <slot name="cta"></slot>
  `.css`
    :host {
      display: block;
    }
  
    #container {
      display: block;
      margin: 0;
      padding: 20px 0;
      overflow: auto hidden;
      scroll-snap-type: x mandatory;
      -webkit-scroll-behavior: smooth;
      --v-home-grid-gap: 20px;
    }

    #container.more {
      margin-bottom: 10px;
    }
  
    #grid {
      margin: 0 auto;
      display: grid;
      grid-template-columns: 
        var(--v-home-grid-gap)
        repeat(${limit}, minmax(min(calc(100% - 4 * var(--v-home-grid-gap)), 300px), 1fr))
        var(--v-home-grid-gap);
      grid-gap: var(--v-home-grid-gap);
    }

    #grid:before,
    #grid:after {
      content: '';
      max-width: var(--v-home-grid-gap);
    }

    #grid ::slotted(*) {
      scroll-snap-align: center;
    }

    @media (min-width: 1024px) {
      #container {
        margin: 0 calc(2 * var(--v-home-grid-gap));
      }

      #container #grid:before, 
      #container #grid:after {
        display: none;
      }

      #grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }

      #container.session #grid {
        grid-template-columns: 100%;
        grid-gap: 40px;
      }
    }

    @media (min-width: 1200px) {
      #container #grid {
        margin: 0 calc(2 * var(--v-home-grid-gap));
      }
    }
  `,
  content: ({ courses, session, more, href, limit }) => html`
    ${courses
      .slice(0, limit)
      .map(
        (course) =>
          html`
            <v-home-course-card
              session="${session}"
              course="${course}"
            ></v-home-course-card>`
      )}
    ${more &&
    html`
      <v-ui-flexbox layout="center" slot="cta">
        <v-ui-button name="See more" type="cta" color="secondary" href="${href}"></v-ui-button>
      </v-ui-flexbox>`}
  `,
});

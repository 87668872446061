import { define, html, store, router } from "hybrids";
import layout from "~/factories/layout";

import Memberships from "./Memberships";

export default define({
  [router.connect]: { url: "/pages/ucif" },
  tag: "v-home-ucif-view",
  render: layout("block"),
  content: () => html`
    <v-home-page>
      <v-home-section>
        <v-ui-text variant="h1" weight="black">
          Univeral Creator Income Fund
        </v-ui-text>
      </v-home-section>
      <v-ui-text ygutter="1">
        We envision a world where music is rightfully honored and valued.
        The Universal Creator Income Fund (UCIF) provides artist communities
        with financing and material support to create new works.
      </v-ui-text>
      <v-ui-text ygutter="1">
        From April 2021 to November 2022, 1620 artists from across the world
        have leveraged the UCIF to generate $100,902.53 from their music.
        The UCIF is funding new projects in Africa, South America, the US,
        and South Asia.
      </v-ui-text>
      <v-ui-text ygutter="1">
        The UCIF is sustained by event producers selling tickets on Viewcy,
        sponsors, and members. As a Viewcy member, you are further empowering
        creative communities to preserve and grow their music culture for all
        of us to enjoy, for generations to come.
      </v-ui-text>

      <v-ui-text ygutter="1">
        <v-ui-text weight="bold">
          Get involved
        </v-ui-text>
        <ol>
          <li>
            <v-ui-link
              href="${router.url(Memberships, { scrollToTop: true })}"
              underline>
              Become a member.
            </v-ui-link>
            Starting at $5/month
          </li>
          <li>
            <v-ui-link
              href="/organize/organizers"
              underline>
              Produce events and sell tickets using Viewcy
            </v-ui-link>
          </li>
        </ol>
      </v-ui-text>
    </v-home-page>
  `.css`
    v-home-page {
      box-sizing: border-box;
    }
    ol {
      padding-left: 1em;
    }
  `,
});

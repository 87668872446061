import { html, define, Component } from "hybrids";
import User from "~/stores/User";

interface VHomeArtists {
  artists: User[];
}

export default define<VHomeArtists>({
  tag: "v-home-artists",
  artists: undefined,
  render: ({ artists }) => html`
    <v-home-card>
      ${artists.map(
        (artist) => html`
          <v-ui-link class="artist" href="${artist.pathname}" block>
            <v-ui-avatar src="${artist.avatar}"></v-ui-avatar>
            <v-ui-text weight="semibold">${artist.name}</v-ui-text>
          </v-ui-link>
        `
      )}
    </v-home-card>
  `.css`
    v-home-card {
      margin: 40px var(--v-ui-layout-spacing) 0;
      padding: var(--v-ui-layout-spacing);
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      grid-gap: var(--v-ui-layout-spacing);
      --v-ui-avatar-size: auto;
    }

    .artist v-ui-avatar {
      margin: 16px;
    }

    .artist v-ui-text {
      text-align: center;
    }

    @media (max-width: 767px) {
      :host {
        --v-ui-font-size-default: 12px;
      }
    }

    @media (min-width: 768px) {
      v-home-card {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      }
    }
  `,
});

import { define, html, store, router } from "hybrids";
import Course, { PER_PAGE } from "~/stores/Course";

interface VHomeFeaturedView {
  page: number;
  courses: Course[];
}

export default define<VHomeFeaturedView>({
  [router.connect]: { url: "/discover/featured" },
  tag: "v-home-featured-view",
  page: 1,
  courses: store([Course], { id: ({ page }) => ({ page, featured: true }) }),
  content: ({ courses, page }) => html`
    <v-home-section back>
      <v-ui-text variant="h1" weight="black" ygutter="1" xgutter="1">
        Featured events by the community
      </v-ui-text>
      <v-home-grid pending="${store.pending(courses)}">
        ${store.ready(courses) &&
        courses.map((course) =>
          html`<v-home-course-card
            course="${course}"
            session="${false}"
          ></v-home-course-card>`.key(course.id)
        )}
      </v-home-grid>
      <v-home-pagination
        page="${page}"
        count="${store.ready(courses) ? courses.length : 0}"
        limit="${PER_PAGE}"
      ></v-home-pagination>
    </v-home-section>
  `,
});

import { define, html, router, store } from "hybrids";

import layout from "~/factories/layout";
import ArtistVideo from "~/stores/ArtistVideo";
import Course from "~/stores/Course";
import Tag from "~/stores/Tag";
import User from "~/stores/User";

import Artists from "./Artists";
import Featured from "./Featured";
import TagMain from "./TagMain";
import TaggedCreators from "./TaggedCreators";
import TaggedUpcomingEvents from "./TaggedUpcomingEvents";
import TaggedRecentEvents from "./TaggedRecentEvents";
import VideoList, { List } from "~/modules/website/views/VideoList";

interface VHomeDiscoverView {
  featured: Course[];
  ondemand: ArtistVideo[];
  artists: User[];
  featuredTags: Tag[];
}

export default define<VHomeDiscoverView>({
  [router.connect]: {
    url: "/",
    stack: [Featured, Artists, TagMain, TaggedCreators, TaggedUpcomingEvents, TaggedRecentEvents, VideoList],
  },
  tag: "v-home-discover-view",
  featured: store([Course], { id: () => ({ page: 1, featured: true }) }),
  ondemand: store([ArtistVideo], { id: () => ({ page: 1 }) }),
  artists: store([User], { id: () => ({ filter: "artists" }) }),
  featuredTags: store([Tag], { id: () => ({ featured: true }) }),
  render: layout("block"),
  content: ({ featured, ondemand, artists, featuredTags }) => html`
    <v-home-section>
      <v-ui-text
        variant="h2" weight="bold" xgutter="3"
      >Explore a World of&nbsp;music
      </v-ui-text
      >
      <v-ui-text color="text-secondary" ygutter="1" xgutter="1">
        And be a patron to the artists who make it.
      </v-ui-text>
    </v-home-section>

    ${store.ready(featuredTags) &&
    !!featuredTags.length &&
    html`
      <v-home-section>
        <v-ui-flexbox layout="center" flow="row wrap" gap="small">
          ${featuredTags.map(
            (featuredTag) => html`
              <v-ui-button
                name="${featuredTag.name}"
                type="cta"
                color="primary"
                href="${router.url(TagMain, { tagObject: featuredTag })}"
              ></v-ui-button>
            `
          )}
        </v-ui-flexbox>
      </v-home-section>`}


    ${store.ready(ondemand) &&
    !!ondemand.length &&
    html`
      <v-home-section>
        <v-ui-text variant="h2" weight="bold" xgutter="1">
          Videos
        </v-ui-text>
        <v-home-course-preview
          courses="${ondemand}"
          href="${router.url(VideoList, { listName: List.ONDEMAND })}"
          limit="9"
        ></v-home-course-preview>
      </v-home-section>`}
    ${store.ready(featured) &&
    !!featured.length &&
    html`
      <v-home-section>
        <v-ui-text variant="h2" weight="bold" ygutter="1" xgutter="1">
          Featured events by the&nbsp;community
        </v-ui-text>
        <v-home-course-preview
          courses="${featured}"
          href="${router.url(Featured)}"
          limit="9"
        ></v-home-course-preview>
      </v-home-section>`}
    ${store.ready(artists) &&
    !!artists.length &&
    html`
      <v-home-section>
        <v-ui-text variant="h2" weight="bold" ygutter="1" xgutter="1">
          Featured creators
        </v-ui-text>
        <v-home-artists artists="${artists.slice(0, 12)}"></v-home-artists>
        ${artists.length > 12 &&
        html`
          <v-ui-flexbox layout="center" slot="cta">
            <v-ui-button
              name="See more"
              type="cta"
              color="secondary"
              href="${router.url(Artists)}"
            ></v-ui-button>
          </v-ui-flexbox>`}
      </v-home-section>
    `}
  `,
});

import { define, html, router, store } from "hybrids";
import layout from "~/factories/layout";
import Session from "~/stores/Session";
import { redirectToSignIn, sendTo } from "~/utils/redirections";

const proposalFormURL = "https://docs.google.com/forms/d/e/1FAIpQLSc6TooDMP3zGcPVAiyUYhi7WzXTBeEch4cHOPLOPgLqlX-CfA/viewform";

export interface VHomeForArtistsView extends HTMLElement {
  session: Session;
  proposing: boolean;
  showErrorMessage: boolean;
}

const submitProposalHandler = async (host: VHomeForArtistsView, event: Event = {}) => {
  try {
    const session = await store.resolve(host.session);
    // TODO Update this condition to check if the current user is an artist
    if (session.user.artist) {
      sendTo(proposalFormURL);
    } else {
      host.showErrorMessage = true;
    }
  } catch (error) {
    redirectToSignIn("proposing=true");
  }
}

export default define<VHomeForArtistsView>({
  [router.connect]: { url: "/organize/artists?proposing" },
  tag: "v-home-for-artists-view",
  session: store(Session),
  showErrorMessage: false,
  proposing: {
    value: false,
    observe(host, value) {
      if (value) {
        submitProposalHandler(host);
      }
    }
  },
  render: layout("block"),
  content: ({ showErrorMessage }) => html`
    ${showErrorMessage && html`
      <v-ui-alert type="danger">
        We don’t recognize you as a Viewcy artist—someone who has performed through Sessions. If you are getting
        this message in error and have a question, or if you would like to introduce yourself and join the community,
        <strong>
          <v-ui-link href="https://discord.gg/cEhVXs2jyq" underline target="_blank" rel="noreferer noopener">
            join our Discord.
          </v-ui-link>
        </strong>
      </v-ui-alert>`}
    <v-home-section>
      <v-ui-text variant="h1" weight="black" xgutter="1">
        Collaborate and access funding
      </v-ui-text>
      <v-ui-text color="text-secondary" ygutter="1" xgutter="1">
        Artist-curated, community funded Sessions, by artists, for artists.
      </v-ui-text>
      <v-ui-flexbox layout="center" slot="cta">
        <v-ui-button
          name="Submit a proposal"
          color="primary"
          type="cta"
          uppercase
          onclick="${submitProposalHandler}"
        ></v-ui-button>
      </v-ui-flexbox>
    </v-home-section>
    <v-ui-text ygutter="1">
      Sessions aim to unlock the centuries old dynamic between patron and artist—an unmatched catalyst for artistic
      brilliance. From the Griots of West Africa, to the legendary composers of Europe, to the Ustads of South Asia,
      patronage has created some of the world’s most advanced and enduring musical traditions. Sessions modernizes
      sponsorship for the internet age and is our best attempt to realize our vision of a world that honors and values
      music. We focus on giving patrons and sponsors the infrastructure and incentives to be creative partners, a
      time-tested context for some of the best creative work in history. This context has three main ingredients:
    </v-ui-text>
    <v-ui-text>
      <ol>
        <li>Funding</li>
        <li>
          A community (a knowledgeable curator, a host, musicians, support
          staff, etc.)
        </li>
        <li>An environment (a virtual venue, and a library)</li>
      </ol>
    </v-ui-text>
    <v-home-section>
      <v-ui-text xgutter="1">
        <v-ui-flexbox flow="row" layout="center">
          <v-ui-button
            name="Submit a proposal"
            color="primary"
            type="cta"
            uppercase
            onclick="${submitProposalHandler}"
          ></v-ui-button>
        </v-ui-flexbox>
      </v-ui-text>
    </v-home-section>
    <v-ui-text ygutter="1">
      Every Session gets access to funding via the
      <strong>
        <v-ui-link href="/pages/ucif" underline target="_blank" rel="noreferer noopener">
          Universal Creator Income Fund (UCIF)
        </v-ui-link>
      </strong>
      — a Viewcy community initiative. Community support is paramount to the cultivation of art and music. On Viewcy,
      individuals can contribute by
      <strong>
        <v-ui-link href="/pages/memberships" underline target="_blank" rel="noreferer noopener">
          joining Viewcy as a member
        </v-ui-link>
      </strong>
    </v-ui-text>
    <v-ui-text ygutter="1">
      Artist must submit a proposal to get funding. Proposals are selected based on three criteria:
      <ol>
        <li>Impact on the diversity of our catalog</li>
        <li>
          Cultural significance
        </li>
        <li>Audio and video quality</li>
      </ol>
    </v-ui-text>
    <v-home-section>
      <v-ui-text xgutter="1">
        <v-ui-flexbox flow="row" layout="center">
          <v-ui-button
            name="Submit a proposal"
            color="primary"
            type="cta"
            uppercase
            onclick="${submitProposalHandler}"
          ></v-ui-button>
        </v-ui-flexbox>
      </v-ui-text>
    </v-home-section>
  `.css`
    v-home-for-artists-view { 
      margin: 0 2rem;
    }
    
    @media (min-width: 1200px) {
      v-home-for-artists-view { 
        margin: 0 16rem;
      }
    }
  `,
});

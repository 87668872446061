import { define, html, store, router } from "hybrids";
import layout from "~/factories/layout";
import User from "~/stores/User";

interface VHomeArtistsView {
  artists: User[];
}

export default define<VHomeArtistsView>({
  [router.connect]: { url: "/discover/artists" },
  tag: "v-home-artists-view",
  artists: store([User], { id: () => ({ filter: "artists" }) }),
  render: layout("flex"),
  content: ({ artists }) => html`
    <v-home-section back>
      <v-ui-text variant="h1" weight="black" ygutter="1" xgutter="1">
        Featured creators
      </v-ui-text>
      <v-home-artists artists="${artists}"></v-home-artists>
    </v-home-section>
  `,
});

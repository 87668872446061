export default Object.entries(import.meta.globEager("./icon-*.svg"))
  .map(([key, value]) => {
    return [
      key.replace("./icon-", "").replace(".svg", ""),
      value.default as string,
    ];
  })
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as { [key: string]: string });
